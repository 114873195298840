@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  input[type="radio"] {
    @apply form-radio cursor-pointer relative h-6 w-6 appearance-none rounded-full border-2 border-solid border-dark-blue;
    @apply focus:shadow-none focus:outline-none focus:ring-0;
  }

  input[type="radio"]:checked {
    @apply bg-salmon text-salmon border-salmon outline-none ring-0;
    @apply focus:border-salmon;
  }

  input[type="checkbox"] {
    @apply form-checkbox cursor-pointer relative h-6 w-6 appearance-none rounded border-2 border-solid border-dark-blue;
    @apply focus:shadow-none focus:outline-none focus:ring-0;
  }

  input[type="checkbox"]:checked {
    @apply bg-salmon text-dark-blue border-salmon outline-none ring-0;
    @apply focus:border-salmon;
  }

  select,
  textarea,
  input[type="text"],
  input[type="date"],
  input[type="number"] {
    @apply rounded-xl;
  }
  
  .portable-text {
    @apply prose prose-sm sm:prose;
  }

  .AccordionContent {
    overflow: hidden;
  }
  .AccordionContent[data-state='open'] {
    animation: slideDown 250ms ease-out;
  }
  .AccordionContent[data-state='closed'] {
    animation: slideUp 250ms ease-out;
  }
  
  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }
  
  @keyframes slideUp {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 0;
    }
  }

  .table-demographics,
  .table-intake,
  .table-symptoms {
    @apply text-xl border-2 border-dark-blue bg-white w-full;
  }

  .table-demographics th,
  .table-intake th,
  .table-symptoms thead {
    @apply text-white bg-dark-blue;
  }
  .table-demographics th,
  .table-intake th {
    @apply text-end;
  }
  
  .table-demographics th,
  .table-demographics td,
  .table-intake th,
  .table-intake td,
  .table-symptoms th,
  .table-symptoms td {
    @apply py-1 px-4 border border-dark-blue;
  }

}
